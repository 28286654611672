body {
  background-color: #093a43;
}

.navbar {
  background-color: #031b2382 !important;
}

.container {
  padding: 0 1em;
}

.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alertComponent {
  position: fixed;
  z-index: 200;
  top: 10%;
  right: 0%;
  box-shadow: 0px 2px 7px #4c9051;
}

#successAlert {
  background-color: #bef5c2;
  color: green;
  border-top: 2px solid green;
}

#declineAlert {
  background-color: #ffe1e1;
  color: red;
  border-top: 2px solid red;
}

.parcelHeaders {
  padding: 10px;
  background-color: #f57539;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.trackingContainer {
  background-color: #f57539;
  padding: 60px;
}

#parcelInput {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  margin-top: 40px;
}

#trackButton {
  background-color: #082d38;
  color: #fff;
  width: 140px;
  height: 50px;
  font-weight: 600;
  margin-top: 20px;
}

#containerIcon {
  width: 60px;
  fill: #fff;
}

thead {
  background-color: #f57539 !important;
  color: #fff !important;
}

.manageBtn {
  width: 81px;
  height: 38px;
  background-color: #082d38;
  color: #fff;
  border-radius: 4px;
}

.createBtn {
  background-color: #f57539;
  color: #fff;
  width: 120px;
  height: 48px;
  border-radius: 5px;
}

.userManageBtn {
  width: 81px;
  height: 38px;
  border: 1px solid #f57539;
  color: #f57539;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .authPart1 {
    background-image: url("./assets/Newsletter-img-blur.jpg");
    background-position: top;
    background-size: cover;
  }

  .authPart2 {
    display: none;
  }

  .parcelCol1 {
    width: 100%;
  }

  .parcelCol2 {
    width: 100%;
    margin-top: 10px;
  }

  .trackingContainer {
    padding: 40px 10px;
  }
}
